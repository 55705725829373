import type { Good } from '@/stores/quote'
import { TypeEnum } from '@apiTypes'
import type { QuoteByHash, QuoteByID, User } from '@apiTypes'

export function quoteTotalWeight(goods: Good[]): number {
  return goods?.reduce((sum, good) => sum + (good.count || 0) * (good.unit_weight || 0), 0)
}

export function formatQuoteTotalWeight(goods: Good[]): string {
  const totalWeight = quoteTotalWeight(goods)

  return totalWeight ? `${totalWeight} kg` : ' - kg'
}

export function formatQuoteDuration(quote: QuoteByID | QuoteByHash | undefined): string {
  const { t } = useI18n()

  const durationInHours = quote?.duration ? +quote.duration : 0

  return `${durationInHours} ${t('hours')}`
}

export function formatQuotePrice(
  quote: QuoteByHash | undefined,
  n: (value: number, options: Intl.NumberFormatOptions, locale?: string) => string,
  t: (key: string) => string,
): { confirmationPrice: string; detailPrice: string; discountAmount: string } {
  const applyVAT = quote?.user ? applyVATCondition(quote.user) : false

  const currencyOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }

  const selectedPrice = applyVAT
    ? quote?.selected_price_option?.pricing.final_price_with_vat
    : quote?.selected_price_option?.pricing.final_price

  const confirmationPrice = selectedPrice
    ? n(+selectedPrice, currencyOptions, 'sk-SK')
    : ''

  const discountAmount = quote?.selected_price_option?.pricing.total_discounts
    ? n(+quote?.selected_price_option?.pricing.total_discounts, currencyOptions, 'sk-SK')
    : ''

  const detailPriceParts: string[] = []

  if (selectedPrice) {
    detailPriceParts.push(n(+selectedPrice, currencyOptions, 'sk-SK'))
    detailPriceParts.push(applyVAT ? t('(VAT included)') : t('(VAT not included)'))
  }

  if (discountAmount > 0)
    detailPriceParts.push(`${t('Discount: -')}${discountAmount}`)

  const detailPrice = detailPriceParts.join('\n')

  return {
    confirmationPrice,
    detailPrice,
    discountAmount,
  }
}

export function formatQuoteDistance(quote: QuoteByID | QuoteByHash | undefined): string {
  return quote?.distance ? `~ ${Math.floor(+quote.distance)} km` : '- km'
}

export async function scrollToFirstError(form: { validate: () => Promise<{ errors: Record<string, string[]> }> }): Promise<void> {
  const validationResult = await form.validate()
  const errorKeys = Object.keys(validationResult.errors)

  if (errorKeys.length > 0) {
    const firstErrorKey = errorKeys[0]
    const fieldElement = document.querySelector(`[name="${firstErrorKey}"]`)
    if (fieldElement)
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export function formatDateTimeRange(lower: string, upper: string, mode: TypeEnum.Pickup | TypeEnum.Delivery = TypeEnum.Pickup): string {
  const lowerDate = new Date(lower)
  const upperDate = new Date(upper)

  const formatDate = (date: Date) => date.toLocaleDateString('sk-SK', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).replace(/\//g, '.')

  const formatTime = (date: Date) => date.toLocaleTimeString('sk-SK', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  if (mode === TypeEnum.Delivery)
    return `${formatDate(upperDate)} ${formatTime(upperDate)}`

  return `${formatDate(lowerDate)} ${formatTime(lowerDate)} - ${formatDate(upperDate)} ${formatTime(upperDate)}`
}

export function applyVATCondition(user: User): boolean {
  return user && (!user.is_vat_payer || (user.is_company && user.country === 'SK') || !user.is_company)
}
