<script setup lang="ts">
import type { PropType } from 'vue'
import VehicleType from '@/views/free-quote/components/VehicleType.vue'
import type { Vehicle } from '@/utils/vehicle'
import { vehicles } from '@/utils/vehicle'
import { VehicleTypeEnum } from '@apiTypes'

const props = defineProps({
  changeable: {
    type: Boolean,
    default: true,
  },
  defaultType: {
    type: String as PropType<VehicleTypeEnum>,
    default: VehicleTypeEnum.VAN,
  },
})

const emit = defineEmits<{
  (e: 'vehicleTypeChanged', vehicleType: VehicleTypeEnum): void
}>()

const { changeable, defaultType } = toRefs(props)
const vehiclesRef = ref<Vehicle[]>(vehicles)
function select(vehicle: Vehicle) {
  if (changeable.value) {
    vehiclesRef.value = vehiclesRef.value.map(item => ({
      ...item,
      selected: item.type === vehicle.type,
    }))
    emit('vehicleTypeChanged', vehicle.type)
  }
}

onMounted(() => {
  if (defaultType.value) {
    vehiclesRef.value = vehiclesRef.value.map(item => ({
      ...item,
      selected: item.type === defaultType.value,
    }))
  }
  if (!changeable.value) {
    vehiclesRef.value = vehiclesRef.value.map(item => ({
      ...item,
      enabled: false,
    }))
  }
})
</script>

<template>
  <div class="vehicle-wrapper rounded-3 text-center">
    <div class="vehicle-container">
      <div class="d-flex gx-2">
        <VehicleType
          v-for="vehicle in vehiclesRef" :key="vehicle"
          :vehicle="vehicle"
          @vehicle-type-changed="select"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@styles/abstracts/colors';

.vehicle-wrapper {
  background-color: $light;
  padding: .5rem .25rem;

  @media (max-width: 992px) {
    padding: .25rem;

    .vehicle-container {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      }

    .d-flex {
      width: max-content;
    }
  }
}
</style>
