<script setup lang="ts">
import type { Vehicle } from '@/utils/vehicle'

const props = defineProps({
  vehicle: {
    type: Object as PropType<Vehicle>,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'vehicleTypeChanged', vehicle: Vehicle): void
}>()

const vehicle = toRef(props, 'vehicle')

function select() {
  if (vehicle.value.enabled) {
    const updatedVehicle = {
      ...vehicle.value,
      selected: true,
    }

    emit('vehicleTypeChanged', updatedVehicle)
  }
}
</script>

<template>
  <div class="col-lg-3 col-4 flex-shrink-0 px-0">
    <div
      class="vehicle text-nowrap rounded-3"
      :class="{
        'cursor-pointer': vehicle.enabled,
        'cursor-disabled': !vehicle.enabled,
        'opacity-75': !vehicle.enabled,
        'disabled': !vehicle.enabled,
        'active': vehicle.selected,
      }"
      @click="select"
    >
      <div v-if="vehicle.isNew" class="banner">
        {{ $t('NEW') }}
      </div>
      <img
        v-if="!vehicle.enabled"
        class="vehicle-icon"
        :src="vehicle.icon_muted"
        :alt="vehicle.title"
      >
      <img
        v-else-if="vehicle.enabled && vehicle.selected"
        class="vehicle-icon cursor-pointer"
        :src="vehicle.icon_selected"
        :alt="vehicle.title"
      >
      <img
        v-else
        class="vehicle-icon cursor-pointer"
        :src="vehicle.icon_default"
        :alt="vehicle.title"
      >
      <div class="fw-bolder mt-2">
        {{ vehicle.title }}
      </div>
      <div>
        {{ vehicle.description }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/abstracts/variables';

.vehicle {
  background-color: white;
  margin: 0 .25rem;
  border: 1px solid transparent;
  padding: 1.3rem 1.3rem;
  position: relative;
  transition: background-color 0.3s ease, border 0.3s ease, transform 0.2s ease;
}

.vehicle:not(.disabled):not(.active):hover {
  //border-color: $dark;
  border-color: $gray;
  background-color: $light-gray;
}

.active {
  background-color: $dark;
  color: white;
}

.banner {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: $danger;
  color: white;
  padding: 0.25rem 1.6rem;
  font-size: 0.65rem;
  font-weight: bold;
  text-transform: uppercase;
  transform: translate(50%, -50%) rotate(45deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  clip-path: polygon(31% 0%, 71% 0%, 100% 100%, 0% 100%);
}
</style>
