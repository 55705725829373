import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { useQuoteStore } from '@/stores/quote'
import { getAllPackagesForVehicleType } from '@/utils/packaging'
import { BinPacking2D, vehicles } from '@/utils/vehicle'
import type { Good } from '@apiTypes'
import { PackageTypeEnum } from '@apiTypes'

export function useGoods(): {
  updateGood: (updatedGood: Good) => void
  removeGood: (identifier: number) => void
  handleTailLiftChange: (isTailLiftSelected: boolean) => void
  goods: ComputedRef<Good[] | undefined>
  totalWeight: ComputedRef<number | undefined>
  addGood: () => void
  validateDimensions: () => boolean
  checkFitAndWarn: () => boolean } {
  const quoteStore = useQuoteStore()
  const toast = useToast()
  const { t } = useI18n()
  const goods = computed(() => quoteStore.getQuoteByID.goods)

  const totalWeight = computed(() => {
    return quoteStore.totalWeight
  })

  const vehicle = computed(() => vehicles.find(item => item.type === quoteStore.getQuoteByID.vehicle_type))
  const vehicleWeight = ref()
  const tailLiftCompatible = ref(false)
  const vehiclePackages = ref()
  const defaultPackage = computed(() => vehiclePackages.value.filter(pkg => pkg.package_type === PackageTypeEnum.EntireVehicle)[0])

  function createGood(): Good {
    return {
      count: defaultPackage.value.count,
      unit_weight: tailLiftCompatible.value ? vehicle.value?.tailLiftWeight : defaultPackage.value.unit_weight,
      height: defaultPackage.value.height,
      length: defaultPackage.value.length,
      width: defaultPackage.value.width,
      package_type: defaultPackage.value.package_type,
      stackable: defaultPackage.value.stackable,
    } as Good
  }

  function addGood() {
    const newGood = createGood()

    quoteStore.addGood(newGood)
    checkFitAndWarn()
  }

  function updateGood(updatedGood: Good) {
    quoteStore.updateGood(updatedGood)
    validateDimensions()
    checkFitAndWarn()
  }

  function removeGood(identifier: number) {
    quoteStore.removeGood(identifier)
  }

  function checkFitAndWarn() {
    const binPacking = new BinPacking2D(vehicle.value, goods.value ?? [])
    const fits = binPacking.fitPackages().fits
    if (!fits) {
      toast.dismiss('floorToast')
      toast.warning(t('The dimensions of your packages specified in your transportation request exceed the floor space of the vehicle you requested, but don\'t worry, we\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.'), {
        id: 'floorToast',
      })

      return false
    }

    return true
  }

  function validateDimensions() {
    let isValid = true

    for (const good of goods.value || []) {
      if (good.height !== null && good.height !== undefined && good.height > vehicle.value?.dimensions?.height) {
        toast.dismiss('heightToast')
        toast.warning(t('You\'ve reached the height limit of selected vehicle, but don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.'), {
          id: 'heightToast',
        })
        isValid = false
      }

      if (good.width !== null && good.width !== undefined && good.width > vehicle.value.dimensions?.width) {
        toast.dismiss('widthToast')
        toast.warning(t('You\'ve reached the width limit of selected vehicle, but don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.'), {
          id: 'widthToast',
        })
        isValid = false
      }

      if (good.length !== null && good.length !== undefined && good.length > vehicle.value?.dimensions?.length) {
        toast.dismiss('lengthToast')
        toast.warning(t('You\'ve reached the width limit of selected vehicle, but don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.'), {
          id: 'lengthToast',
        })
        isValid = false
      }

      if (totalWeight.value !== undefined && totalWeight.value > vehicleWeight.value) {
        toast.dismiss('weightToast')
        toast.warning(t('The vehicle you chose for your transportation request cannot accommodate the weight of your shipment, but don\'t worry, we\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.'), {
          id: 'weightToast',
        })
        isValid = false
      }
    }

    return isValid
  }

  function refreshPackages() {
    vehiclePackages.value = getAllPackagesForVehicleType(vehicle.value?.type)
  }

  function refreshGoods() {
    if (goods.value && goods.value.length > 0) {
      const entireVehiclePkg = goods.value.find(good => good.package_type === PackageTypeEnum.EntireVehicle)
      if (entireVehiclePkg) {
        const newPkg = createGood()

        entireVehiclePkg.unit_weight = newPkg.unit_weight

        updateGood(entireVehiclePkg)
      }
      validateDimensions()
    }
  }

  function handleTailLiftChange(isTailLiftSelected: boolean) {
    vehicleWeight.value = isTailLiftSelected ? vehicle.value?.tailLiftWeight : vehicle.value?.weight
    tailLiftCompatible.value = isTailLiftSelected
    refreshPackages()
    refreshGoods()
  }

  handleTailLiftChange(false)

  return { addGood, updateGood, removeGood, goods, totalWeight, handleTailLiftChange, validateDimensions, checkFitAndWarn }
}
